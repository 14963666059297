$fontColor: #000000;
$linkColor: #fff;
$linkColor-visited: #acacac;
$backgroundColor: #96ebff;
$backgroundGradiantStart: #96EBFF;
$backgroundGradiantEnd: #129AFA;
$backgroundPrimaryColor: #50B9FF;
$backgroundPrimaryGradiantStart: #50B9FF;
$backgroundPrimaryGradiantEnd: #36A8FF;

//$headerColor: #888
//$tagBackgroundColor: #999
//$tagDefaultColor: #fff
//$separatorColor: $linkColor

//*
//font-family: Verdana
//font-size: 1em
//font-color: $fontColor

.bg-blue {
  background: $backgroundColor;
  background: -webkit-linear-gradient(to left, $backgroundGradiantStart, $backgroundGradiantEnd);
  background: linear-gradient(to left, $backgroundGradiantStart, $backgroundGradiantEnd); }


section.contact ul.list-social {
    margin-bottom: 0; }

section.contact ul.list-social li a {
    font-size: 40px;
    line-height: 80px;
    display: block;
    width: 80px;
    height: 80px;
    color: #fff;
    border-radius: 100%; }

section.contact ul.list-social li.social-twitter a {
    background-color: #1da1f2; }

section.contact ul.list-social li.social-twitter a:hover {
    background-color: #0d95e8; }

section.contact ul.list-social li.social-facebook a {
    background-color: #3b5998; }

section.contact ul.list-social li.social-facebook a:hover {
    background-color: #344e86; }

section.contact ul.list-social li.social-google-plus a {
    background-color: #dd4b39; }

section.contact ul.list-social li.social-google-plus a:hover {
    background-color: #d73925; }
